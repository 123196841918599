import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"
const About = () => {
  return (
    <Layout>
      <Metadata title="About" description="Coaster Experts About Page" />
      <h1>About Page</h1>
      <div className="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="light" data-type="VERTICAL" data-vanity="abarboza" data-version="v1"><a className="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/abarboza/en-us?trk=profile-badge">Ariel Barboza</a></div>

      <p>
        Ariel Barboza and Hilary Pisula are technology and Human Resource
        experts. Bringing the solutions that your park requires.
      </p>
      <p>Our blog contains some of our adventures!</p>
    </Layout>
  )
}

export default About
